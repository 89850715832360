<script>
import { mapState } from 'vuex';
import config from '@/vue.config';

export default {
    name: 'DashboardCoreDrawer',

    props: {
        expandOnHover: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        loading: false,

        items: [
            {
                icon: 'mdi-view-dashboard',
                title: 'dashboard',
                to: '/pages/dashboard',
            },
            {
                icon: 'mdi-account-group-outline',
                title: 'user',
                to: '/pages/users',
            },
            {
                icon: 'mdi-account-group-outline',
                title: 'shareholders',
                to: '/pages/shareholders',
            },
            {
                icon: 'mdi-account-star',
                title: 'sales_manager',
                to: '/pages/sales-manager',
            },
            // {
            //     icon: 'mdi-home-city',
            //     title: 'projects',
            //     to: '/pages/projects',
            // },
            {
                icon: 'mdi-cash-plus',
                title: 'transactions',
                to: '/pages/transactions',
            },
            {
                icon: 'mdi-certificate',
                title: 'paper_certificates',
                to: '/pages/certificate',
            },
            {
                icon: 'mdi-check-circle-outline',
                title: 'reserved',
                to: '/pages/reserved',
            },
            // {
            //     icon: 'mdi-package',
            //     title: 'packages_shares',
            //     to: '/pages/packages-shares',
            // },
            {
                icon: 'mdi-bank',
                title: 'payment_systems',
                to: '/pages/payment-systems',
            },
            {
                icon: 'mdi-percent',
                title: 'commissions',
                to: '/pages/commissions',
            },
            {
                icon: 'mdi-file',
                title: 'add_documents',
                to: '/pages/add-documents',
            },
        ],
    }),

    computed: {
        ...mapState(['barColor', 'barImage']),

        drawer: {
            get() {
                return this.$store.state.drawer;
            },
            set(val) {
                this.$store.commit('SET_DRAWER', val);
            },
        },

        computedItems() {
            return this.items.map(this.mapItem);
        },

        profile() {
            return {
                avatar: true,
                title: this.$t('avatar'),
            };
        },
    },

    methods: {
        mapItem(item) {
            return {
                ...item,
                children: item.children
                    ? item.children.map(this.mapItem)
                    : undefined,
                title: this.$t(item.title),
            };
        },
        async logout() {
            this.loading = true;

            await this.axios
                .get(config.store.auth.logout, {
                    headers: {
                        Authorization: this.$cookie.get('auth_token_admin'),
                    },
                })
                .then(response => {
                    this.$cookie.delete('auth_token_admin');
                    this.$router.push('/');
                })
                .catch(error => {
                    this.$alertify.error(error.response.data.message);
                });
            this.loading = false;
        },
    },
};
</script>
<template>
    <v-navigation-drawer
        id="core-navigation-drawer"
        v-model="drawer"
        :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
        :expand-on-hover="expandOnHover"
        :right="$vuetify.rtl"
        :src="$cookie.get('image_bg_nav') || barImage"
        mobile-breakpoint="960"
        app
        width="270"
        v-bind="$attrs"
    >
        <template v-slot:img="props">
            <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
        </template>

        <v-divider class="mb-1" />
        <v-list dense nav>
            <v-list-item>
                <v-list-item-avatar
                    class="align-self-center"
                    color="#c8e7ca"
                    contain
                >
                    <v-img src="../../../../assets/favicon-180x180.png" />
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title
                        class="display-1"
                        v-text="profile.title"
                    />
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-divider class="mb-2" />

        <v-list expand nav>
            <div />
            <template v-for="(item, i) in computedItems">
                <base-item-group
                    v-if="item.children"
                    :key="`group-${i}`"
                    :item="item"
                >
                </base-item-group>

                <base-item v-else :key="`item-${i}`" :item="item" />
            </template>
            <div />
        </v-list>

        <template v-slot:append>
            <v-btn class="bg-btn" :loading="loading" block @click="logout">
                Logout
            </v-btn>
        </template>
    </v-navigation-drawer>
</template>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .25

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px

.theme--dark.v-btn.v-btn--has-bg
    background-color: rgba(255, 50, 50, 0.8)
</style>
